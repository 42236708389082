import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Link } from 'react-scroll';
import { ChakraProvider } from "@chakra-ui/react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react"

import { Box } from "@chakra-ui/react"
import "focus-visible/dist/focus-visible"

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import {FaCalendarCheck, FaGifts} from "react-icons/fa"
import {GiClothes, GiParachute, GiLockedChest } from "react-icons/gi"
import {IoIosRocket} from "react-icons/io"
import {SiLaunchpad} from "react-icons/si"

import snobVid from './snobs.mp4'

SwiperCore.use ([ Navigation, Pagination, Scrollbar, A11y ]);

import Navig from './Components/Nav_Bar/Navig'
import MobileNavig from './Components/Nav_Bar/MobileNavig'
import classes from './Components/Nav_Bar/NavBar.module.css'

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: none;
  border: none;
  background-color: #20B2AA;
  padding: 15px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 270px;
  cursor: pointer;

  }

  :hover {
    color:#fff;
    background-color:#60efda;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  max-width: 1170px;
  position: relative;
  margin: 0 auto;
  padding: 2em 0;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 250px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 400px;
  @media (min-width: 900px) {
    width: 400px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Choose quantity and click MINT to buy your NFT(s)`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  });

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (

    <s.Screen style={{ background: "linear-gradient(to right, rgb(32, 37, 45), rgb(9, 9, 9), rgb(9, 9, 9))", width: "100%", }}>

{/* RESPONSIVE NAVIGATION SECTION*/}  

<div className={classes.navContainer}>
    <div className={classes.navInner}>
      <div className={classes.navLogo}><a href="https://cryptosnobs.io"><img alt={"CryptoSnobs"} src={"./config/images/cs_logo.svg"}/></a></div>
      <div className={classes.navLogoMobile}><a href="https://cryptosnobs.io"><img alt={"CryptoSnobs"} src={"./config/images/cs_logo_single.svg"}/></a></div>
      <div className={classes.Nav_Bar}>
        <Navig />
        <MobileNavig />
      </div>
      <div className={classes.navSocial}>
      <a class="menuIcons" href="https://twitter.com/CryptoSnobs" target="_blank" rel="noreferrer"><svg class="icon" width="36" height="36" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clipTwitter)"><path d="M5.66262 16.3126C12.4534 16.3126 16.1687 10.6851 16.1687 5.80646C16.1687 5.64825 16.1652 5.48654 16.1582 5.32833C16.8809 4.80566 17.5046 4.15825 18 3.41653C17.3269 3.71601 16.6122 3.9116 15.8804 3.99661C16.651 3.53474 17.2279 2.80917 17.5043 1.95439C16.7794 2.38398 15.9867 2.68702 15.1601 2.85052C14.6031 2.25873 13.8667 1.8669 13.0648 1.7356C12.2628 1.6043 11.4399 1.74085 10.7233 2.12413C10.0067 2.50742 9.43635 3.11609 9.10039 3.85605C8.76442 4.596 8.68157 5.42602 8.86465 6.21779C7.39687 6.14413 5.96095 5.76284 4.64999 5.09864C3.33902 4.43443 2.18227 3.50214 1.25473 2.3622C0.7833 3.17499 0.639042 4.1368 0.851273 5.05213C1.0635 5.96746 1.6163 6.76765 2.3973 7.29005C1.81097 7.27144 1.23748 7.11357 0.724219 6.8295V6.87521C0.723693 7.72817 1.01857 8.555 1.55873 9.21514C2.09889 9.87528 2.85099 10.328 3.68719 10.4963C3.14404 10.6449 2.57399 10.6666 2.02113 10.5596C2.25709 11.2931 2.71618 11.9347 3.33433 12.3948C3.95248 12.8549 4.69884 13.1105 5.46926 13.126C4.16132 14.1534 2.54563 14.7107 0.882422 14.708C0.587465 14.7076 0.292799 14.6895 0 14.6539C1.68964 15.7379 3.65515 16.3136 5.66262 16.3126Z" fill="white"></path></g><defs><clipPath id="clipTwitter"><rect width="18" height="18" fill="white"></rect></clipPath></defs></svg></a>
      <a class="menuIcons" href="https://discord.gg/MQZVXPyeEd" target="_blank" rel="noreferrer"><svg class="icon" width="36" height="28" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2601 1.32388C14.0866 0.787192 12.8404 0.396046 11.5396 0.177734C11.3759 0.459724 11.194 0.841767 11.0666 1.14195C9.68307 0.941827 8.30951 0.941827 6.94504 1.14195C6.81769 0.841767 6.62666 0.459724 6.47201 0.177734C5.16216 0.396046 3.91595 0.787192 2.7507 1.32388C0.394744 4.79869 -0.242005 8.19163 0.0763687 11.5391C1.64094 12.6761 3.15096 13.3675 4.63458 13.8223C4.99844 13.3311 5.32591 12.8035 5.6079 12.2486C5.07121 12.0485 4.56181 11.8029 4.07061 11.5118C4.19796 11.4209 4.32531 11.3208 4.44356 11.2208C7.40898 12.5761 10.6209 12.5761 13.5499 11.2208C13.6773 11.3208 13.7955 11.4209 13.9229 11.5118C13.4317 11.8029 12.9223 12.0485 12.3856 12.2486C12.6676 12.8035 12.9951 13.3311 13.3589 13.8223C14.8416 13.3675 16.3607 12.6761 17.9171 11.5391C18.3082 7.66407 17.2976 4.29842 15.2601 1.32388ZM6.01723 9.47422C5.12577 9.47422 4.39806 8.66466 4.39806 7.67317C4.39806 6.68167 5.10758 5.87205 6.01723 5.87205C6.91775 5.87205 7.65454 6.68167 7.63634 7.67317C7.63634 8.66466 6.91775 9.47422 6.01723 9.47422ZM11.9945 9.47422C11.103 9.47422 10.3744 8.66466 10.3744 7.67317C10.3744 6.68167 11.0848 5.87205 11.9945 5.87205C12.895 5.87205 13.6318 6.68167 13.6136 7.67317C13.6136 8.66466 12.9041 9.47422 11.9945 9.47422Z" fill="white"></path></svg></a>
      </div>
    </div>
</div>


     

{/* HERE IS THE START OF THE HEADER SECTION*/}

<ResponsiveWrapper style={{ width: "100%" }}>
  <s.Container id={"header"} flex={1} jc={"center"} ai={"left"} >
        <s.TextTitle style={{ textAlign: "left", fontSize: 45, fontWeight: 600, width: "100%", paddingLeft: "20px", }}> 
        What is CryptoSnobs?
        </s.TextTitle>
          <s.SpacerSmall />
        <s.TextDescription style={{ textAlign: "left", fontSize: 20, width: "90%", paddingLeft: "20px",}}> 
        CryptoSnobs is a collection of 8,128 generative artworks held by a community of enthusiasts who take their crypto a little more seriously.
        When crypto has eaten the world, our 'moment-in-time' collectibles will be monuments to the snobs everywhere that inspired them.
        </s.TextDescription>
          <s.SpacerLarge />
      
        <StyledButton style={{ marginLeft: "20px", width: "250px"}}>
                      
          <a href={"https://discord.gg/MQZVXPyeEd"} target="_blank" rel="noreferrer">
            <img alt={"Join Us On Discord"} src={"/config/images/discord.svg"} style={{ width: "30px", float: "left", marginRight: "15px"}}/>JOIN US ON DISCORD
          </a>
        </StyledButton>
            
            <s.SpacerSmall />

  </s.Container>
            <s.SpacerSmall />
  <s.Container flex={1} jc={"center"} ai={"center"}>
      <video loop={"true"} autoPlay={"true"} muted={"true"} playsInline={"true"}>
        <source src={ snobVid } type={"video/mp4"} 
        class={"sample"} style={{ visibility: "visible", opacity: "1", transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)", transition: "opacity 0.8s cubic-bezier(0.5, 0, 0, 1) 0.5s, transform 0.8s cubic-bezier(0.5, 0, 0, 1) 0.5s"}} />
        Your browser does not support the video tag. See images of our art in the carousel below.
      </video>

  </s.Container>
</ResponsiveWrapper>


{/* HERE IS THE START OF THE ABOUT SECTION*/}

<div style={{ width: "100%", backgroundImage: `url("/config/images/new_yorkOP4narrow.svg")`, backgroundSize: "auto 100%"}}>

    <s.Container id={"about"} jc={"center"} ai={"center"} style={{ width: "100%", }}>
          <img alt={"border1"} src={"/config/images/border_swirl.svg"} style={{ width: "300px", }}/>

      <s.TextDescription style={{ textAlign: "center", fontSize: 60, fontWeight: 600 }}>
          About
      </s.TextDescription>
        <s.SpacerSmall />
      <s.TextDescription style={{ textAlign: "centre", fontSize: 20,  width: "70%", }}>
          Each of our 8,128 crypto moments features eye-opening quotes from experts  
          who believed they knew better - and the BTC closing price on the day they said it. All 
          immortalized as non-fungible tokens (NFT) for the very first time on the Ethereum blockchain.<br/><br/>
          These unique, hand-drawn artefacts are a verifiable historical record of the new Web 3.0 world we're 
          helping to build - and a reminder of the inexorable march of crypto technology and innovation.
      </s.TextDescription>
          <s.SpacerLarge />
    
    <div id={"butn"} >
        <StyledButton style={{ marginLeft: "20px", width: "250px"}}>
                      
          <a href={"https://discord.gg/MQZVXPyeEd"} target="_blank" rel="noreferrer">
          <img alt={"Join Us On Discord"} src={"/config/images/discord.svg"} style={{ width: "30px", float: "left", marginRight: "15px"}}/>JOIN US ON DISCORD
          </a>
        </StyledButton>
            
          <s.SpacerSmall />

     </div>   
          <s.SpacerLarge />

    </s.Container>
</div>

{/* HERE IS THE START OF THE SWIPER SECTION */}

<s.Container id={"collection"} jc={"center"} ai={"center"} style={{ width: "100%",}}>
      <img alt={"border1"} src={"/config/images/border_swirl.svg"} style={{ width: "300px", }}/>
    <s.TextDescription style={{ textAlign: "center", fontSize: 60, fontWeight: 600, width: "100%"}}>
        There's all kinds of CryptoSnobs
    </s.TextDescription>
      <s.SpacerSmall />
    <s.TextDescription style={{ textAlign: "centre", fontSize: 20,  width: "70%", }}>
        With millions of potential generative combinations, you can be sure your artefact is unique and will identify you as 
        a member of a select group. Some are more rare than others but all are equally perfect as a profile pic, collection piece, or even tee shirt design. 
    </s.TextDescription>
      <s.SpacerLarge/>
      <s.SpacerSmall/>

  <Swiper 
    modules={[Navigation, Pagination, Scrollbar, A11y]}
    //slidesOffsetAfter={30}
    //slidesPerView={3} 
    centeredSlides={1}
    loop={1}
    initialSlide={3}
    spaceBetween={20}
    navigation
    //pagination={{"clickable": true}} 
    // scrollbar={{ draggable: true }}
    onSwiper={(swiper) => console.log(swiper)}
    onSlideChange={() => console.log('slide change')}
    breakpoints={{
      "640": {
      "slidesPerView": 1,
      "spaceBetween": 20,
      },
      "768": {
      "slidesPerView": 2,
      "spaceBetween": 20
      },
      "1024": {
      "slidesPerView": 3,
      "spaceBetween": 20
      }
      }} 
      className="mySwiper">

    <SwiperSlide >    
      <img alt={"snob_image_1"} src={"/config/images/1.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>
      <img alt={"snob_image_2"} src={"/config/images/2.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>    
      <img alt={"snob_image_3"} src={"/config/images/3.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>    
      <img alt={"snob_image_4"} src={"/config/images/4.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>    
      <img alt={"snob_image_5"} src={"/config/images/5.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>
      <img alt={"snob_image_6"} src={"/config/images/6.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>    
      <img alt={"snob_image_7"} src={"/config/images/7.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>    
      <img alt={"snob_image_8"} src={"/config/images/8.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>    
      <img alt={"snob_image_9"} src={"/config/images/9.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>    
      <img alt={"snob_image_10"} src={"/config/images/10.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>
    <SwiperSlide>    
      <img alt={"snob_image_11"} src={"/config/images/11.png"} style={{ width: "350px", marginRight: "50px" }}/> 
    </SwiperSlide>

  </Swiper>
</s.Container>

{/* HERE IS THE START OF THE MINTING SECTION*/}

<s.Container>
  <img alt={"border1"} src={"/config/images/border_swirl.svg"} style={{ width: "300px", }}/>
</s.Container>
      
  <s.Container
        id={"mint"}
        flex={1}
        ai={"left"}
        style={{ padding: 0, }}
        image={CONFIG.SHOW_BACKGROUND ? "/" : null} 
        >

    <ResponsiveWrapper flex={1} style={{ padding: 24, width: "100%",}} >
        
    <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"quotes"} src={"/config/images/quotes.png"} style={{ width: "500px" }}/>
          </s.Container>
          <s.SpacerLarge />

      <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "#000",
              padding: 24,
              borderRadius: 10,
              border: "1px solid var(--secondary)",
                            // boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
          <s.TextDescription className={"mintText"} style={{ textAlign: "center", fontSize: 40, fontWeight: 600, }}>
            Mint Your CryptoSnob
          </s.TextDescription>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              
 {/* HIDE CONTRACT ADDRESS

              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
*/}

            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The minting sale has now ended
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  But you can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>

          {/*}      <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontWeight: "bold" }}
                >
                  1 {CONFIG.SYMBOL} = {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>
            <s.SpacerXSmall />     */}
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontWeight: "bold"  }}
                >
                  PRICE TO BE CONFIRMED
                </s.TextDescription>
                <s.SpacerSmall />
 
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (

                  <s.Container ai={"center"} jc={"center"} >
                    
                  <div style={{ background: "#eb4a7b", color: "#b0b0b0", width: "100%", borderRadius: "5px", padding: "10px" }}>    
 
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      <strong>LAUNCHING SOON</strong>
{/*}  RE-ACTIVATE LINE    Connect to the {CONFIG.NETWORK.NAME} network      */}
                    </s.TextDescription>
                </div>          

                    <s.SpacerLarge />

{/*}  RE-ACTIVATE METAMASK BUTTON at Launch      */}

                    <StyledButton className={"metamaskButton"}
                    >
                      <img alt={"metamask"} src={"/config/images/MetaMask_Fox.svg"} style={{ width: "30px", }}/>
                      CONNECT TO METAMASK
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                      <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4, borderRadius: "5px", background: "#f28b27", color: "white" }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                        <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                        <s.SpacerMedium />
                      <StyledRoundButton
                        style={{ lineHeight: 0.4, borderRadius: "5px", background: "#f28b27", color: "white" }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                      <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton 
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY..." : "MINT"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}

          </s.Container>
            <s.SpacerLarge />

        </ResponsiveWrapper>

 {/* RE-ACTIVATE DISCLAIMER TEXT AT LAUNCH

    <s.Container jc={"center"} ai={"center"} style={{ width: "66%" }}>
      <s.TextDescription style={{textAlign: "center", color: "var(--primary-text)",}}>
            Please be sure you're connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address.
            Once you make your NFT purchase, you can't undo the transaction.
      </s.TextDescription>
          <s.SpacerSmall />
      <s.TextDescription style={{textAlign: "center", color: "var(--primary-text)",}}>
            We have set the gas limit to {CONFIG.GAS_LIMIT} gwei for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
        </s.TextDescription>
    </s.Container>
        
            */}              

{/* HERE IS THE START OF THE FAQ SECTION*/}

<s.Container id={"faq"} jc={"center"} ai={"center"} style={{ width: "100%" }}>
      <img alt={"border1"} src={"/config/images/border_swirl.svg"} style={{ width: "300px", }}/>
    <s.TextDescription style={{ textAlign: "center", fontSize: 60, fontWeight: 600,  }}>
        FAQ
    </s.TextDescription>
      <s.SpacerLarge />
    
  <ChakraProvider>
    <Accordion allowMultiple width={"66%"} textAlign={"center"}>
      <AccordionItem width={"100%"} color={"white"} borderBottom={"1px"} borderTop={"1px"} borderColor={"white"}>
        <h2>
          <AccordionButton >
            <Box color={"white"} borderWidth={"1px"} mx={"auto"} fontWeight={"bold"} padding={"3"}>
              What is CryptoSnobs?
            </Box>
            <AccordionIcon />
          </AccordionButton >
        </h2>
        <AccordionPanel pb={4} textColor={"#ffffff"} >
          CryptoSnobs is a community of crypto enthusiasts who see the change happening in the world around them and embrace it. 
          The NFT collection is an opportunity to secure rare artefacts that capture the essence of a time when traditonal power-brokers 
          began to lose control.  
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem width={"100%"} color={"white"} borderBottom={"1px"} borderColor={"white"}>
        <h2>
          <AccordionButton >
            <Box color={"white"} borderWidth={"1px"} mx={"auto"} fontWeight={"bold"} padding={"3"}>
              When can I buy?
            </Box>
            <AccordionIcon />
          </AccordionButton >
        </h2>
        <AccordionPanel pb={4} textColor={"#ffffff"} >
          We're building our community and will be launching soon. More details to follow on this site, in our Discord, and on Twitter.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem width={"100%"} color={"white"} borderBottom={"1px"} borderColor={"white"}>
        <h2>
          <AccordionButton >
            <Box color={"white"} borderWidth={"1px"} mx={"auto"} fontWeight={"bold"} padding={"3"}>
              Is there a whitelist?
            </Box>
            <AccordionIcon />
          </AccordionButton >
        </h2>
        <AccordionPanel pb={4} textColor={"#ffffff"} >
          Yes. We will have an invitation-only whitelist minting period prior to the general mint. The whitelist will be 
          drawn from early active users on our Discord channel. 
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem width={"100%"} color={"white"} borderBottom={"1px"} borderColor={"white"}>
        <h2>                  
          <AccordionButton >
            <Box color={"white"} borderWidth={"1px"} mx={"auto"} fontWeight={"bold"} padding={"3"}>
              What CryptoSnob will I get after minting?
            </Box>
            <AccordionIcon />
          </AccordionButton >
        </h2>
        <AccordionPanel pb={4} textColor={"#ffffff"}>
          All CryptoSnobs are unique in their own way - but some are more rare than others. Your CryptoSnob NFT 
          will be randomly generated at mint. Reveals will be scheduled to maximize fairness and minimize unfair 
          practices throughout the minting process. More details to be provided on this site, in our Discord, and on Twitter.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem width={"100%"} color={"white"} borderBottom={"1px"} borderColor={"white"}>
        <h2>
          <AccordionButton >
            <Box color={"white"} borderWidth={"1px"} mx={"auto"} fontWeight={"bold"} padding={"3"}>
              How much does one CryptoSnob cost to mint?
            </Box>
            <AccordionIcon />
          </AccordionButton >
        </h2>
        <AccordionPanel pb={4} textColor={"#ffffff"}>
          To be confirmed.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem width={"100%"} color={"white"} borderBottom={"1px"} borderColor={"white"}>
        <h2>
          <AccordionButton >
            <Box color={"white"} borderWidth={"1px"} mx={"auto"} fontWeight={"bold"} padding={"3"}>
              How many can I buy?
            </Box>
            <AccordionIcon />
          </AccordionButton >
        </h2>
        <AccordionPanel pb={4} textColor={"#ffffff"}>
          There is a maximum of 3 CryptoSnobs available to each buyer.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem width={"100%"} color={"white"} borderBottom={"1px"} borderColor={"white"} >
        <h2>
          <AccordionButton >
            <Box color={"white"} borderWidth={"1px"} mx={"auto"} fontWeight={"bold"} padding={"3"}>
              Tokenomics
            </Box>
            <AccordionIcon />
          </AccordionButton >
        </h2>
        <AccordionPanel pb={4} textColor={"#ffffff"} >
          Only 8,128 CryptoSnob NFTs will be minted. 200 will be reserved for business purposes from the initial mint (including 5 rare ones). 100 will go to the team/support crew and the remainder 
          will be used for marketing (e.g. giveaways, contests, games, quizzes, and airdrops).
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem width={"100%"} color={"white"} borderBottom={"1px"} borderColor={"white"} >
        <h2>
          <AccordionButton >
            <Box color={"white"} borderWidth={"1px"} overflow={"hidden"} mx={"auto"} fontWeight={"bold"} padding={"3"}>
              How do I get my hands on one?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} >
          1. Go to the official site:  https://cryptosnobs.io <br />
          2. Connect your Metamask wallet using the "CONNECT TO METAMASK" button <br />
          3. Press the "MINT" button <br />
          4. Choose the number of CryptoSnob NFTs to buy, then press "MINT"
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </ChakraProvider>

</s.Container>

{/* HERE IS THE START OF THE TEAM SECTION*/}

  <s.Container id={"team"} jc={"center"} ai={"center"} style={{ width: "100%" }}>
        <img alt={"border1"} src={"/config/images/border_swirl.svg"} style={{ width: "300px", }}/>

        <s.TextDescription style={{ textAlign: "center", fontSize: 60, fontWeight: 600, }}>
            Team
        </s.TextDescription>
          <s.SpacerMedium/>
        <s.TextDescription style={{ textAlign: "center", fontSize: 20, width: "70%" }}>
          Our allstar team is doing it tough so the community can reap the rewards
        </s.TextDescription>
  </s.Container>

<ResponsiveWrapper style={{ width: "100%" }}>
  <s.Container flex={1} jc={"center"} ai={"center"} style={{padding: "50px"}}>
      <img alt={"Jeeves"} src={"/config/images/jeeves.png"} style={{width: "250px", borderRadius: "50%", border: "7px solid", color: "#ffffff" }}/>
          <s.SpacerSmall />
        <s.TextTitle style={{ textAlign: "left", fontSize: 30 }}>
          JEEVES
        </s.TextTitle>
        <s.TextDescription style={{ textAlign: "left", fontSize: 20 }}>
          Artist
        </s.TextDescription>
  </s.Container>
          <s.SpacerSmall />
  <s.Container flex={1} jc={"center"} ai={"center"}>
      <img alt={"Preston"} src={"/config/images/preston.png"} style={{width: "250px", borderRadius: "50%", border: "7px solid", color: "#ffffff"}}/>
          <s.SpacerSmall />
        <s.TextTitle style={{ textAlign: "left", fontSize: 30 }}>
          PRESTON
        </s.TextTitle>
        <s.TextDescription style={{ textAlign: "left", fontSize: 20 }}>
          Tech
        </s.TextDescription>
  </s.Container>
          <s.SpacerSmall />
  <s.Container flex={1} jc={"center"} ai={"center"} style={{padding: "50px"}}>
      <img alt={"Edward"} src={"/config/images/edward.png"} style={{width: "250px", height: "250px", borderRadius: "50%", border: "7px solid", color: "#ffffff", transform: "scaleX(-1)", }}/>
            <s.SpacerSmall />
        <s.TextTitle style={{ textAlign: "left", fontSize: 30 }}>
          EDWARD
        </s.TextTitle>
        <s.TextDescription style={{ textAlign: "left", fontSize: 20, }}>
          Marketing
        </s.TextDescription>
  </s.Container>
</ResponsiveWrapper>
    

{/* HERE IS THE START OF THE ROADMAP SECTION*/}

<s.Container id={"roadmap"} jc={"center"} ai={"center"} style={{ width: "100%" }}>
    <img alt={"border1"} src={"/config/images/border_swirl.svg"} style={{ width: "300px", }}/>

      <s.TextDescription style={{ textAlign: "center", fontSize: 60, fontWeight: 600,  }}>
          Roadmap
      </s.TextDescription>
        <s.SpacerSmall />
      <s.TextDescription style={{ textAlign: "left", fontSize: 20,  width: "70%", }}>
      We're building a community of crypto enthusiasts who share the belief in crypto's ability to 
      change lives and change the world. Sure, you want to make money on your NFT and we think our 
      unique and rare artworks will help you achieve that. But we’re also connecting people that want 
      to grow with crypto – creating a place where enthusiasts can learn, share, discover, and be entertained. 
      We've got loads of exciting ideas planned. Be part of it! 
    </s.TextDescription>
        <s.SpacerLarge />
  <ResponsiveWrapper>
    <s.Container flex={1} jc={"center"} ai={"center"}>
      <VerticalTimeline>
        <VerticalTimelineElement
          className={"vertical-timeline-element--work"}
          contentStyle={{ background: "#ffffff", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  #ffffff" }}
          //date={"04 November, 2021"}
          iconStyle={{ background: 'linear-gradient(to right, rgb(217, 119, 6), rgb(220, 38, 38))', color: '#fff'}}
          icon={<IoIosRocket />}
          >

          <h3 className={"vertical-timeline-element-title"} style={{ fontSize: 25, fontWeight: 700, color: "#000"}}>Launch</h3>
          <p style={{ fontSize: 15, fontWeight: 400, color: "#000"}}>
          <strong>LAUNCHING SOON<br/>
          We're building our community.</strong><br/>
          We'll be announcing developments, answering questions, running competitions, and generally having fun on Discord 
          and Twitter over the coming weeks.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={"vertical-timeline-element--work"}
          contentStyle={{ background: "#ffffff", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  #ffffff" }}
          //date={"X% Sold"}
          iconStyle={{ background: 'linear-gradient(to right, rgb(217, 119, 6), rgb(220, 38, 38))', color: '#fff' }}
          icon={<GiLockedChest />}
          >

          <h3 className={"vertical-timeline-element-title"} style={{ fontSize: 25, fontWeight: 700, color: "#000"}}>Community Treasury</h3>
          <p style={{ fontSize: 15, fontWeight: 400, color: "#000"}}>
          As a CryptoSnob holder you will be eligible to participate in decisions regarding the CryptoSnobs Community Treasury. 10% of revenue from 
          the general mint will be deposited into the Treasury at 25%, 50%, 75%, and 100% intervals of the mint sale. Treasury activities will eventually be guided by 
          CryptoSnobs holders, who will have input into ideas, experiences, events, and campaigns.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={"vertical-timeline-element--work"}
          contentStyle={{ background: "#ffffff", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  #ffffff" }}
          //date={"X% Sold"}
          iconStyle={{ background: 'linear-gradient(to right, rgb(217, 119, 6), rgb(220, 38, 38))', color: '#fff' }}
          icon={<GiClothes />}
          >

          <h3 className={"vertical-timeline-element-title"} style={{ fontSize: 25, fontWeight: 700, color: "#000"}}>The Merch</h3>
          <p style={{ fontSize: 15, fontWeight: 400, color: "#000"}}>
          We're making arrangements with key partners to launch some funky, exclusive merch for those that like that kind of thing.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={"vertical-timeline-element--work"}
          contentStyle={{ background: "#ffffff", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  #ffffff" }}
          //date={"X% Sold"}
          iconStyle={{ background: 'linear-gradient(to right, rgb(217, 119, 6), rgb(220, 38, 38))', color: '#fff' }}
          icon={<FaCalendarCheck />}
          >

          <h3 className={"vertical-timeline-element-title"} style={{ fontSize: 25, fontWeight: 700, color: "#000"}}>Exclusive Events</h3>
          <p style={{ fontSize: 15, fontWeight: 400, color: "#000"}}>
          Join us in the back room. We'll be hosting exclusive AMA events with mystery guests and bringing you insider 
          NFT and token launch insights from up and coming teams.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={"vertical-timeline-element--work"}
          contentStyle={{ background: "#ffffff", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  #ffffff" }}
          //date={"X% Sold"}
          iconStyle={{ background: 'linear-gradient(to right, rgb(217, 119, 6), rgb(220, 38, 38))', color: '#fff' }}
          icon={<FaGifts />}
          >

          <h3 className={"vertical-timeline-element-title"} style={{ fontSize: 25, fontWeight: 700, color: "#000"}} >Grand Snob Giveaway</h3>
          <p style={{ fontSize: 15, fontWeight: 400, color: "#000"}}>
          Upon full sale completion, we'll be running an awesome competition with prizes of 8 NFTs each for five lucky holders. 
          More details to be provided on Discord.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className={"vertical-timeline-element--work"}
          contentStyle={{ background: "#ffffff", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  #ffffff" }}
          //date={"X% Sold"}
          iconStyle={{ background: 'linear-gradient(to right, rgb(217, 119, 6), rgb(220, 38, 38))', color: '#fff' }}
          icon={<SiLaunchpad />}
          >

          <h3 className={"vertical-timeline-element-title"} style={{ fontSize: 25, fontWeight: 700, color: "#000"}}>Digital Expansion</h3>
          <p style={{ fontSize: 15, fontWeight: 400, color: "#000"}}>
          The future is bright. All CryptoSnobs NFT holders receive priority access to our digital expansion editions.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </s.Container>

  </ResponsiveWrapper>
          

    </s.Container>   {/* THIS MIGHT BE A CONTAINER ENDING TAG FOR NEARLY THE WHOLE DOCUMENT*/}
</s.Container>
        
{/* HERE IS THE START OF THE FOOTER SECTION */}


  <div className="footerBar">

      <ul className="footerMenu">
          <li className="menuItems"><Link to='header' smooth={true}>Top</Link></li>
          <li className="menuItems"><Link to='about' smooth={true}>About</Link></li>
          <li className="menuItems"><Link to='mint' smooth={true}>Mint</Link></li>
          <li className="menuItems"><Link to='faq' smooth={true}>FAQ</Link></li>
          <li className="menuItems"><Link to='team' smooth={true}>Team</Link></li>
          <li className="menuItems"><Link to='roadmap' smooth={true}>Roadmap</Link></li>
      </ul>

    <div className="footerMenuIcons">
      <ul className="footerIcons">
        <li className="menuIcons"><a href={"https://discord.gg/MQZVXPyeEd"}><svg width="80px" height="30px" viewBox="0 0 24 24">
                    <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028 14.09 14.09 0 0 0 1.226-1.994.076.076 0 0 0-.041-.106 13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"></path>
                  </svg></a></li>
        <li className="menuIcons"><a href={"https://twitter.com/CryptoSnobs"}><svg width="80px" height="30px" viewBox="0 0 33 33">
                    <path d="M30.414,10.031c0.014,0.297,0.021,0.595,0.021,0.897c0,9.187-6.992,19.779-19.779,19.779c-3.928,0-7.58-1.149-10.657-3.123
          c0.546,0.063,1.099,0.095,1.658,0.095c3.26,0,6.254-1.107,8.632-2.974c-3.039-0.058-5.607-2.065-6.491-4.828
          c0.424,0.082,0.858,0.125,1.308,0.125c0.635,0,1.248-0.084,1.83-0.244c-3.177-0.639-5.576-3.448-5.576-6.815
          c0-0.029,0-0.058,0-0.087c0.939,0.521,2.01,0.833,3.15,0.869C2.646,12.48,1.419,10.35,1.419,7.938c0-1.274,0.343-2.467,0.94-3.495
          c3.427,4.206,8.552,6.973,14.327,7.263c-0.117-0.509-0.18-1.038-0.18-1.584c0-3.838,3.112-6.949,6.953-6.949
          c1.998,0,3.805,0.844,5.07,2.192c1.582-0.311,3.072-0.89,4.416-1.686c-0.521,1.624-1.621,2.986-3.057,3.844
          c1.406-0.166,2.746-0.54,3.991-1.092C32.949,7.826,31.771,9.05,30.414,10.031z"></path>
                  </svg></a></li>
      </ul>
    </div>

  </div>

</s.Screen>
  );
}

export default App;
