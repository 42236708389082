import NavLinks from './NavLinks'
import classes from './NavBar.module.css'


const Navig = () => {
    return ( 
    
     <nav className={classes.Navig}>
        <NavLinks />
    </nav>

     );
}

export default Navig;