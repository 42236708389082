import NavLinks from './NavLinks'
import classes from './NavBar.module.css'
import {CgMenu} from 'react-icons/cg'
import {GrClose} from 'react-icons/gr'
import { useState } from 'react'


const MobileNavig = () => {

    const [open, setOpen] = useState(false);

    const hamburgerIcon = <CgMenu className={classes.Hamburger} 
                        size='30px' color='white'
                        onClick={() => setOpen(!open)}
                        />

    const closeIcon = <GrClose className={classes.Hamburger} 
                        size='30px' color='white' 
                        onClick={() => setOpen(!open)}
                        />

    const closeMobileMenu = () => setOpen(false);

    return ( 
        <nav className={classes.MobileNavig}>
        
        {open ? closeIcon : hamburgerIcon}
        {open && <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu}/>}
        </nav>
     );
}

export default MobileNavig;