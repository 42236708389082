import { Link } from 'react-scroll';


const NavLinks = (props) => {
    return ( 
        
    <ul>

      <li><Link to='about' onClick={() => props.isMobile && props.closeMobileMenu()} smooth={true}>ABOUT</Link></li>
      <li><Link to='mint' onClick={() => props.isMobile && props.closeMobileMenu()} smooth={true}>MINT</Link></li>
      <li><Link to='faq' onClick={() => props.isMobile && props.closeMobileMenu()} smooth={true}>FAQ</Link></li>
      <li><Link to='team' onClick={() => props.isMobile && props.closeMobileMenu()} smooth={true}>TEAM</Link></li>
      <li><Link to='roadmap' onClick={() => props.isMobile && props.closeMobileMenu()} smooth={true}>ROADMAP</Link></li>

    </ul>


     );
}

export default NavLinks;